.lin a {
  margin-top: 2rem;
}
.images img {
  width: 100%;
  height: 100%;
}
.images .col-4 {
  margin-top: 3rem;
}
.images .box:hover {
  border: solid 6px green;
  transition: 0.4s;
}
.images .box {
  text-align: center;
}
.images .box p {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 1.3rem;
  color: gray;
}
.menu a {
  display: block;
  padding: 10px;
  background-color: transparent;
  text-decoration: none;
  color: #333;
}

.menu .active {
  background-color: #0f752a;
  color: white;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid red;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.images .col-12 {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 992px) {
  .images {
    overflow-x: hidden;
  }
}
