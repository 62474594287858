.am {
  margin-top: 7rem;
}
.am h2 {
  font-weight: 700;
  font-size: 2.3rem;
  text-align: center;
}
.am .pp {
  text-align: center;
  color: #1b1464;
}
