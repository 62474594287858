.ima img {
  width: 100%;
  height: 100%;
}
.ima {
  margin-top: 7rem;
}
.ima .box {
  position: relative;
}
.ima .boxx {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 1s;
}
.ima .box:hover .boxx {
  opacity: 1;
}
.ima .box {
  margin-top: 2rem;
}
