.goo {
  margin-top: 7rem;
}
.goo .col-12 img {
  width: 70%;
}
.goo .im img {
  width: 90%;
}
.goo .im,
.goo .ii {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.goo a {
  color: #0f752a;
}
@media screen and (max-width: 576px) {
  .goo .ii img {
    width: 100%;
  }
}
