.eco {
  margin-top: 7rem;
}
.eco .bb {
  text-align: center;
  margin-top: 2rem;
}
.eco .bb img {
  margin-top: 3rem;
}
.ecoo {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.ecoo img {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.eco img {
  width: 100%;
}
