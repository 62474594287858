.wordp {
  margin-top: 7rem;
}
.wordp h2 {
  text-align: center;
  font-weight: 700;
}
.wordp .pp {
  text-align: center;
  color: #1b1464;
}

.wordp img {
  width: 90%;
}
.wordp .col-6 {
  margin-top: 3rem;
}
.wordpp img {
  height: 20rem;
}
.jackl {
  margin-top: 1rem;
}
.sectionn44 h2 {
  font-weight: 700;
  text-align: center;
  font-size: 2.3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.sectionn44 .pp {
  text-align: center;
  color: #1b1464;
}
.sectionn44 p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.sectionpp {
  background-color: white;
}

@media screen and (max-width: 576px) {
  .maine h4,
  .maine h2 {
    font-size: 1.4rem;
  }
}
