.vi iframe {
  width: 100%;
  height: 25rem;
}
.vi {
  margin-top: 7rem;
}
.vi .col-11 {
  text-align: center;
}
.vi h1 {
  text-align: center;
  font-weight: 700;
  font-size: 2.1rem;
  margin-top: 4rem;
}
.vi p {
  text-align: center;
}
.videos h1 {
  text-align: center;
  font-weight: 700;
  font-size: 2.1rem;
  margin-top: 4rem;
}
.videos p {
  text-align: center;
}
.videos iframe {
  width: 100%;
  margin-top: 3rem;
  height: 20rem;
}
.videos .vid {
  box-shadow: 0 0 2px 0 black;
  padding: 2rem 1rem;
  margin-top: 2rem;
}
.break {
  margin-top: 7rem;
}
.videos {
  margin-top: 7rem;
}
.break h1 {
  font-size: 2.3rem;
  font-weight: 700;
  text-align: center;
}
.break i {
  font-size: 7rem;
}
.break .col-2 {
  margin-top: 4rem;
}
.break .col-10 {
  margin-top: 4rem;
}
.vidd .col-12 {
  text-align: center;
}
.vidd img {
  width: 80%;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.vidd h1 {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
}
.vidd {
  margin-top: 7rem;
}
@media screen and (max-width: 768px) {
  .mv i {
    font-size: 4rem;
  }
  .mv h1 {
    font-size: 1.2rem;
  }
  .mv h4 {
    font-size: 1.2rem;
  }
  .mv p {
    font-size: 1rem;
  }
  .videos,
  .vi,
  .section0 {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 576px) {
  .mv .col-sm-2 {
    text-align: center;
  }
}
