.imggs img {
  width: 100%;
}
.imggs {
  margin-top: 7rem;
}
.imggs .col-6 {
  margin-top: 3rem;
}

.rang {
  margin-top: 7rem;
}
.rang .box {
  padding: 1rem 0.5rem;
  background-color: whitesmoke;
  border-radius: 10px;
}
.rang img {
  border-radius: 50%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 6rem;
}
.rang i {
  font-size: 1.3rem;
}
.rang .col-6 {
  margin-top: 3rem;
}
