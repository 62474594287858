.ho {
  margin-top: 14rem;
}
.ho .col-12 {
  background-color: #738942;
}
.ho img {
  width: 50%;
}

.ho .col-12 {
  text-align: center;
  color: white;
}
.ho h1 {
  margin-top: 1.4rem;
  font-weight: 700;
}
.ho a {
  color: white;
  font-weight: 700;
}
.ho .col-12 {
  position: relative;
  padding: 14rem 1rem 5rem 1rem;
}
.ho img {
  position: absolute;
  top: -8rem;
  right: 21rem;
}
.revv .col-12 {
  background-color: #f7f7f7;
}
.revv .col-7 h1,
.revv .col-7 p {
  color: black;
}
.opt h1 {
  text-align: center;
  font-weight: 700;
}
.opt p {
  text-align: center;
}
.opt {
  margin-top: 7rem;
}
.oppt img {
  width: 100%;
  margin-top: 2rem;
}
.side img {
  width: 85%;
  height: 93%;
}
.side {
  margin-top: 14rem;
}
.side .col-8 {
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 gray;
  padding: 2rem 1rem;
  height: 20rem;
}
.parr img {
  width: 100%;
}
.side .col-8,
.side .col-4 {
  margin-top: 3rem;
}
@media screen and (max-width: 992px) {
  .side .col-lg-4 img {
    height: 10rem;
    margin-bottom: 3rem;
    width: 10rem;
  }
  .side .col-lg-4 {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .revv .col-7 h1 {
    font-size: 1.4rem;
  }
  .revv .col-7 p {
    font-size: 0.7rem;
    font-weight: 500;
  }
  .par .col-md-3 {
    text-align: center;
    margin-top: 2rem;
  }
  .ho img {
    position: absolute;
    top: -8rem;
    right: 10rem;
  }
  .revv {
    display: none;
  }
}
