* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  line-height: 1.4rem;
  letter-spacing: 0.1rem;
}
.dropdown-menu {
  width: 24rem !important;
}
.dropdown-menu li a:hover {
  background-color: #1b1464;
  color: white;
}
.dropdown-menu .mainli:hover {
  background-color: #2c3e50;

  padding: 0.5rem 1rem;
  color: white;
}
.navbar img {
  height: 6rem;
}
.dropdown-menu li .mainli {
  font-weight: 700;
}
.ol {
  overflow-y: scroll;
  height: 30rem;
}
.navbar .active {
  font-weight: 700;
}
.navbar .nav-item {
  margin-left: 1.3rem;
}
.navbar ul {
  margin-top: 0.4rem;
  margin-left: 4rem;
}
.navbar form {
  align-items: center;
}
.navbar form {
  margin-left: 5rem;
}
.navbar .container {
  margin-left: 3.5rem;
}
.navbar form i {
  border: none;
  font-size: 1.7rem;
}
.navbar form i:hover {
  cursor: pointer;
}
.navbar .nav-item {
  position: relative;
}
.navbar .nav-item::after {
  content: "";
  width: 0;
  transition: 0.5s;
  background-color: #2c3e50;
  height: 3px;
  position: absolute;
  left: 0;

  bottom: 0rem;
}
.navbar .nav-item:hover::after {
  width: 100%;
}
.navbar .active:hover {
  color: #1b1464;
}
.navbar form i:hover {
  color: #1b1464;
}
