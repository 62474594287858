@media screen and (max-width: 768px) {
  .maineeeee h4 {
    font-size: 1.3rem;
  }
  .maineeeee h1 {
    font-size: 1.4rem;
  }
  .maineeeee h2 {
    font-size: 1.4rem;
  }
  .sectiono55 .col-lg-4 .pp {
    margin-top: 5.5rem;
  }
}
