.section000 img {
  height: 20rem;
}
.section000 {
  margin-top: 7rem;
}
.section4 a {
  text-decoration: none;
  font-weight: 700;
  color: white;
}
.section555 {
  background-color: white;
}
.section555 .pp {
  color: #0f752a;
  font-weight: 700;
}
.section555 .col-3 i {
  margin-top: 2rem;
}
.section666 img {
  width: 100%;
}
.section666 .col-9 {
  margin: 0 auto;
}
.section666 {
  margin-top: 7rem;
}
.section666 .col-12 {
  text-align: center;
  margin-top: 3rem;
}
.section777 {
  background-color: #443f35;
  padding: 3rem 1rem;
}
.section777 img {
  width: 100%;
}
.section777 h1 {
  color: white;
  font-weight: 700;
  text-align: center;
}
.section777 h1 span {
  color: #0f752a;
}
.section777 .col-6 p {
  text-align: center;
  color: white;
  font-weight: 700;
}
.section777 .form {
  background-color: #0f752a;
  padding: 3rem 1rem;
}
.section777 .form button {
  width: 100%;
  font-weight: 700;
}
.section777 .col-6 {
  margin-top: 3rem;
}
.section777 input {
  padding: 0.6rem 0.3rem;
  font-weight: 700;
}
.section888 {
  margin-top: 7rem;
}
.section888 h1 {
  text-align: center;
}
.section888 .p {
  text-align: center;
  color: #1b1464;
}
.section888 img {
  height: 15rem;
}
.section888 .img {
  text-align: center;
}
.section5555 {
  margin-top: 4rem;
  text-align: center;
}
.section5555 .col-6 {
  margin-top: 7rem;
}
.section5555 .mid {
  margin: 0 auto;
  margin-top: 7rem;
}
.commann .pp {
  text-align: center;
  font-size: 2.3rem;
  font-weight: 700;
  margin-top: 3rem;
}
@media screen and (min-width: 1200px) {
  .section000 img {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .section000 img {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .holy .fa-laptop {
    margin-bottom: 2rem;
  }
  .holy .pp {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 992px) {
  .maindd h4 {
    font-size: 1.4rem;
  }
  .holly .pp {
    font-size: 1.4rem;
  }
}
