.formm {
  margin-top: 7rem;
  margin-bottom: 2rem;
}
.formm .col-md-8 {
  background-color: whitesmoke;
  padding: 2rem 1rem;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 gray;
}
.col-12 input {
  padding: 2rem 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.col-12 label {
  margin-top: 1rem;
}
.col-md-8 .ch input {
  border-radius: 50%;
}
.formm .col-md-4 img {
  width: 100%;
  margin-top: 15rem;
}
.formm .col-md-4 p {
  margin-top: 4rem;
}
@media screen and (max-width: 500px) {
  .lab label {
    font-size: 0.8rem !important;
  }
  .lab p {
    font-size: 0.8rem;
  }
}
